$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;


.search-card-dimensions {
	min-height: 17.5rem;
	max-height: 17.5rem;
	width: 12.7rem;
	max-width: 12.7rem;
}
.thumbnail-size {
	width: 5rem;
	height: 5rem;
	min-width: 5rem;
}
.location-card-height {
	height: auto;
	min-height: none;
	max-height: none;
}
.chain-card-dimensions {
	@apply w-[200px] h-74;
}
